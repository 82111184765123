.Contact-container {
    background-color: black;
    background-image: url('/public/fotos/fotos-pc/imagen-final.jpg');
    background-repeat: no-repeat;
    background-size: 139.5%;
    background-position-y: -11.4vw;
    background-position-x: 0px;
    max-width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.Contact {
    background: linear-gradient(rgba(0, 0, 0), rgba(0, 0, 0, 0.4));
    max-width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
}

.Title-contact {
    max-width: 100vw;
    width: auto;
    height: 5vw;
    display: flex;
    /* position: relative; */
    /* top: 10vw; */
    /* left: 10vw; */
    color: white;
    font-family: 'Urbanist-Bold';
    font-size: 3vw;
    letter-spacing: 0.3rem;
    padding-block-start: 6vw;
    padding-inline-start: 8vw;
}

.Contact-linea {
    display: flex;
    align-items: center;
    /* position: relative; */
    top: 180px;
    /* margin-bottom: 70px; */
    left: 180px;
    flex-direction: row;
    width: auto;
    height: auto;
    padding: 5vw 11vw;
}

.Contact-emoji {
    cursor: pointer;
    width: 3vw;
    height: auto;
    filter: invert(90%);
}

.Contact-letra {
    display: flex;
    text-align: center;
    font-family: 'Urbanist-ExtraLight';
    font-size: 1.2vw;
    letter-spacing: 0.18rem;
    color: white;
    justify-content: left;
    margin-left: 65px;
    align-items: center;
    width: 50%;
    height: auto;
}

.foto-final-movil {
    background-image: none;
}

.final-sentence-block {
    display: none;
}



@media (max-width:768px) {
    .Contact-container {
        /* background-color: rgb(44, 38, 38); */
        background-image: none;
        background-size: 0;
        background-position-y: 0;
    }

    .Contact {
        /* background: linear-gradient(rgba(0, 0, 0), rgb(15, 1, 22)); */
        padding-bottom: 3vw;
        height: auto;
    }

    .Title-contact {
        height: auto;
        justify-content: center;
        align-items: center;
        font-size: 12vw;
        letter-spacing: 0.3rem;
        padding-top: 17vw;
        padding-bottom: 10vw;
        padding-inline-start: 0;
    }

    .Contact-linea {
        flex-direction: column;
        justify-content: center;
        padding-bottom: 8vw;
        width: auto;
    }

    .Contact-emoji {
        width: 10vw;
        max-width: 70px;
        height: auto;
    }

    .Contact-letra {
        justify-content: center;
        align-items: center;
        font-size: 4vw;
        margin-left: 0;
        width: auto;
        height: auto;
        padding-top: 5vw;
    }

    .foto-final-movil {
        background-image: url('/public/fotos/fotos-movil/imagen-final.jpg');
        height: 100vw;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

    }

    .final-sentence-block {
        display: flex;
        align-items: center;
        text-align: center;
        background-color: rgba(6, 0, 9, 0.8);
        height: auto;
        min-height: 100px;
        width: 100vw;
        position: absolute;
        bottom: 0;
        color: white;
        font-family: 'Urbanist-semibold';
        font-size: 1em;
        letter-spacing: 3.5px;
    }

    .final-sentence {
        margin: 5px;
    }
}
.About {
    display: flex;
    flex-direction: row-reverse;
    width: 100vw;
    height: auto;
    background-color: rgba(0, 0, 0, 1);
}

.Title {
    background-color: rgb(255, 255, 255);
    font-family: 'Urbanist-Black';
    display: flex;
    font-size: 3vw;
    padding-left: 2vw;
    padding-top: 2vw;
}

.contenido {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: auto;
    font-family: 'Montserrat-Regular';
    font-size: .8vw;
    letter-spacing: 0.03rem;
    margin: 0;
    justify-content:center ;
}

.contenedor-contenido {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2vw;
}


.Descripcion {
    width: 50vw;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.Foto_desc {
    background-image: url('/public/fotos/fotos-pc/imagen-bio.jpg');
    background-size: cover;
    background-position: right center;
    width: 50vw;
    height: auto;
}

.contenedor-icon {
    display: flex;
    padding-top: 2vw;
    padding-bottom: 2vw;
}


.contenedor-icon img {
    width: 3.3vw;
    height: 3.3vw;
    margin-right: 3vw;
    cursor: pointer;
}

.badge-img {
    max-width: 20%;
    padding-bottom: 4vw;
  }

  .contenedor-icon .badge-img{
    max-width: 80px;
    padding-bottom: 0;
    width: auto;
    height: 3.3vw;
    margin-right: 3vw;
    cursor: pointer;
  }
  


@media (max-width:768px) {

    .About {
        flex-direction: column;
    }

    .Title {
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 12vw;
        padding-top: 17vw;
        padding-bottom: 10vw;
        padding-left: 0;
        height: auto;
    }

    .contenido {
        height: auto;
        font-family: 'Montserrat-Regular';
        font-size: .8em;
        letter-spacing: 0.03rem;
    }

    .contenedor-contenido {
        height: auto;
        justify-content: center;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .Descripcion {
        width: 100%;
        height: auto;
    }

    .Foto_desc {
        width: 100%;
        height: 100vw;

    }

    .contenedor-icon {
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        top: 0;
        width: 100%;
        height: auto;
        padding: 7vw 0;
    }

    .contenedor-icon img {
        width: 15vw;
        max-width: 55px;
        height: 15vw;
        max-height: 55px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .contenedor-icon .badge-img{
        padding-bottom: 0;
        width: 15vw;
        max-width: 55px;
        height: 15vw;
        max-height: 55px;
        margin-right: 10px;
        margin-left: 10px;
      }
 
    .badge-img {
      max-width: 30vw; /* Ajusta el tamaño máximo según sea necesario */
    }
  
  


}
.music-player {
  display: none;
  flex-direction: column;
  align-items: center;
  background-image: url('/public/fotos/audio.JPG');
  background-size: cover;
  background-position: -100% 55%;
  border: 1px solid #ffffff;
  border-radius: 40px;
  padding: 10px;
  z-index: 1000;
  width: 18%;
  box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.9),
    2px -2px 10px rgba(255, 255, 255, 0.9),
    -2px -2px 10px rgba(255, 255, 255, 0.9),
    -2px 2px 10px rgba(255, 255, 255, 0.9);
}

.music-player-titulo {
  font-family: Pixel;
  font-size: 200%;
  text-align: center;
  color: wheat;
  margin-top: 1%;
}


.music-player .controls {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.music-player button {
  background-color: #050358;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

.music-player button:hover {
  background-color: #2980b9;
}

.music-player button:focus {
  outline: none;
}

.music-player .song-info {
  margin-top: 10px;
  text-align: center;
  color: #ffffff;
}



@media (max-width:768px) {
  .music-player {
    display: none;
    flex-direction: column;
    align-items: center;
    background-image: url('/public/fotos/audio.JPG');
    background-size: cover;
    background-position: -100% 55%;
    border: 1px solid #ffffff;
    border-radius: 40px;
    padding: 10px;
    z-index: 1000;
    width: 18%;
    box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.9),
      2px -2px 10px rgba(255, 255, 255, 0.9),
      -2px -2px 10px rgba(255, 255, 255, 0.9),
      -2px 2px 10px rgba(255, 255, 255, 0.9);
  }
}
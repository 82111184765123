@font-face {
    font-family: 'Pixel';
    src: url('/public/fonts/PixelifySans-Bold.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'Young';
    src: url('/public/fonts/YoungSerif-Regular.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'Babylonica';
    src: url('/public/fonts/Babylonica-Regular.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'Staatliches';
    src: url('/public/fonts/Staatliches-Regular.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'Urbanist-semibold';
    src: url('/public/fonts/Urbanist-SemiBold.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'Urbanist-Bold';
    src: url('/public/fonts/Urbanist-Bold.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'Urbanist-Regular';
    src: url('/public/fonts/Urbanist-Regular.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'Urbanist-Black';
    src: url('/public/fonts/Urbanist-Black.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'Urbanist-Light';
    src: url('/public/fonts/Urbanist-Light.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'Urbanist-ExtraLight';
    src: url('/public/fonts/Urbanist-ExtraLight.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url('/public/fonts/Montserrat-Regular.ttf') format('truetype');
    font-display: block;
}
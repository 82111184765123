.Media {
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    background-size: contain;
}

.Title-media {
    width: 100vw;
    padding-top: 3vw;
    padding-bottom: 3vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    font-family: 'Urbanist-Bold';
    font-size: 3vw;
    letter-spacing: 0.3rem;
}



/* IMAGE SLIDER*/

.fotos-container {
    width: 100vw;
    height: auto;
    overflow: hidden;
    background: linear-gradient(rgb(21, 2, 31), rgb(11, 1, 17));
    padding-bottom: 2vw;
}

.carousel-container {
    height: auto;
}

.flickity-enabled:focus {
    outline: none;
}

.carousel-container img {
    height: auto;
    max-height: 30vw;
    max-width: 90vw;
    object-fit: cover;
    border-radius: 50px;
    margin: 25px;
    user-select: none;
    border-radius: 10px;
}

.flickity-viewport {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flickity-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

.fotos {
    position: relative;

}

.fotos .flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
}

.fotos .flickity-prev-next-button {
    position: absolute;
    top: 50%;
    width: 100px;
    height: 100px;
    border-radius: 20%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.333);
    cursor: pointer;
    padding: 0;
    fill: bisque;
}

.fotos .flickity-prev-next-button.next {
    left: 90%;
    right: auto;
}

.fotos .flickity-prev-next-button.previous {
    right: 90%;
    left: auto;
}



/* VIDEO */

.slider-container-video {
    max-width: 100vw;
    height: auto;
    background: linear-gradient(rgb(11, 1, 17), rgb(0, 0, 0));
    overflow-x: hidden;
}

.video-container {
    height: auto;
    background-color: rgb(0, 0, 0);
}

.video-container .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
    background: linear-gradient(#09010e, rgb(0, 0, 0));
}

.video-container iframe {
    width: 45vw;
    height: 25vw;
    object-fit: cover;
    margin: 25px;
    border: none;
}

.video {
    position: relative;
}


.video .flickity-page-dots {
    width: 100%;
    display: flex;
    justify-content: center;
    height: auto;
    padding-left: 0;
    margin-top: 3vw;
    margin-bottom: 7vw;
}

.video .flickity-rtl .flickity-page-dots {
    direction: rtl;
}

.video .flickity-page-dots .dot {
    display: inline-block;
    width: 3vw;
    height: 3vw;
    margin: 0 2vw;
    background: #ffffff;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
}

.video .flickity-page-dots .dot.is-selected {
    opacity: 1;
}



@media (max-width:768px) {
    .Media {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        background-size: contain;
        padding-bottom: 10vw;
        background-color: rgb(0, 0, 0);
    }

    .Title-media {
        height: auto;
        padding-top: 15vw;
        padding-bottom: 8vw;
        align-items: center;
        text-align: center;
        color: white;
        font-family: 'Urbanist-Bold';
        font-size: 12vw;
        letter-spacing: 0.3rem;
    }



    /* IMAGE SLIDER*/

    .fotos-container {
        width: 100vw;
        height: auto;
        overflow: hidden;
        padding-bottom: 2vw;
    }

    .carousel-container {
        height: auto;
    }


    .carousel-container img {
        height: auto;
        max-height: 50vw;
        max-width: 90vw;
        object-fit: cover;
        border-radius: 0;
        margin: 25px;
        user-select: none;
        border-radius: 10px;
    }

    .fotos .flickity-slider {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .fotos {
        position: relative;

    }

    .fotos .flickity-button {
        display: none;
    }



    /* VIDEO */

    .slider-container-video {
        width: 100vw;
        height: auto;
        background-color: rgb(0, 0, 0);
        overflow-x: hidden;
    }

    .video-container {
        height: auto;
    }

    .video-container iframe {
        width: 90vw;
        height: 50vw;
        object-fit: cover;
        border: none;
    }

    .video {
        position: relative;
    }

    .video .flickity-page-dots {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        width: 100%;
        height: auto;
        margin-bottom: 3vw;
        padding-top: 8vw;
    }

    .video .flickity-rtl .flickity-page-dots {
        direction: rtl;
    }

    .video .flickity-page-dots .dot {
        display: inline-block;
        width: 5vw;
        height: 5vw;
        margin: 0 3vw;
        background: #ffffff;
        border-radius: 50%;
        opacity: 0.25;
        cursor: pointer;
    }

    .video .flickity-page-dots .dot.is-selected {
        opacity: 1;
    }
}
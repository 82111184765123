.loading {
    background-image: url('/public/fotos/fotos-movil/img-video.png');
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    text-shadow: 3px 0 1px black;
    font-family: 'Pixel';
    font-size: 10vw;
}

.Cover {
    width: 100vw;
    height: 100vh;
    justify-content: center;
    overflow: hidden;
    
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.First_sentence_block {
    width: 100%;
    min-height: 9vw;
    height: auto;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.First_sentence {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 5px;
    color: white;
    font-family: 'Urbanist-semibold';
    font-size: 1.7vw;
    letter-spacing: 3.5px;
}


@media (max-width:768px) {

    .video-background {
        display: flex;
        z-index: -2;
    }

    .First_sentence_block {
        min-height: 100px;
        z-index: -1;
    }

    .First_sentence {
        font-size: 1em;
        letter-spacing: 3.5px;
        z-index: -1;
    }

}
.Toolbar {
    background-color: transparent;
    display: flex;
    position: fixed;
    width: 100vw;
    height: auto;
    z-index: 999;
    transition: background-color 1s ease, opacity 0.5s ease, height 0.5s ease;
}

.Toolbar.scroll-down {
    height: auto;
    background-color: rgba(0, 0, 0, 1);
}

.Toolbar.at-top {
    background-color: transparent;
    display: flex;
    position: fixed;
    min-width: 100vw;
    height: auto;
    z-index: 999;
}

.Toolbar.is-pause {
    background-color: transparent;
}


.Toolbar-nombre {
    display: flex;
    width: 30vw;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.Toolbar-nombre2 {
    width: 100%;
    font-size: 64px;
    font-family: 'Babylonica';
    letter-spacing: 3px;
    color: white;
    display: flex;
    text-align: center;
    justify-content: left;
    transition: font-size 1.5s ease;
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.Toolbar-nombre2.is-pause {
    font-size: 0vw;
    transition: font-size 1.5s ease;
}

.Toolbar-nombre2.at-top {
    width: 100%;
    font-size: 64px;
    font-family: 'Babylonica';
    letter-spacing: 3px;
    color: white;
    display: flex;
    text-align: center;
    justify-content: center;
    transition: font-size 1.5s ease;
}

.Toolbar-button {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: 10px;
    width: 70vw;
}

.Button-style {
    margin: 20px 70px 20px 4px;
    color: white;
    -webkit-text-stroke: .5px black;
    text-stroke: 2px black;
    font-family: 'Staatliches';
    letter-spacing: 0.4rem;
    font-size: 30px;
    font-weight: bold;
    background-color: rgb(0, 0, 0, 0);
    cursor: pointer;
    transition: font-size 1.5s ease;
}

.Button-style.is-pause {
    font-size: 0;
    transition: font-size 1.5s ease;
}

.HamburguesaButton {
    display: none;
}


@media (max-width:1580px) {
    .Toolbar-nombre {
        width: 70vw;
    }

    .Toolbar-button {
        display: none;
    }

    .HamburguesaButton {
        display: flex;
        justify-content: right;
        width: 30vw;
        cursor: pointer;
        color: black;
        margin-bottom: 3vw;
        font-size: 70px;
        padding-right: 80px;
        position: relative;
        top: 8px;
    }

    .HamburguesaButton.scroll-down {
        color: white;

    }

    .HamburguesaButton.is-pause {
        font-size: 0;
        transition: font-size 1.5s ease;

    }

    .Mobile-menu {
        background-image: url('/public/icon.png');
        background-repeat: no-repeat;
        background-position: center center;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(26, 4, 33, 0.985);
        z-index: 999 !important;
    }

    .Mobile-menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(0, 0, 0);
        font-family: 'Staatliches';
        letter-spacing: 0.4rem;
        font-size: 50px;
        font-weight: bold;
        cursor: pointer;
        width: 100%;
        height: 15vh;
        z-index: 999 !important;
        -webkit-text-stroke: 3px yellow;
    }

    .close-icon {
        position: absolute;
        top: 30px;
        right: 80px;
        font-size: 80px;
        color: rgb(164, 164, 164);
        font-family: 'Pixel';
        cursor: pointer;
    }
}


@media (max-width:768px) {


    .Toolbar-nombre2 {
        font-size: 7vw;
        margin-left: 15px;
        width: 70vw;
    }

    .Toolbar-nombre2.at-top {
        width: 100%;
        font-size: 10px;
        font-family: 'Babylonica';
        letter-spacing: 3px;
        color: white;
        display: flex;
        text-align: center;
        justify-content: center;
        transition: font-size 1.5s ease;
    }

    .Toolbar-button {
        display: none;
    }

    .HamburguesaButton {
        font-size: 12vw;
        padding-right: 20px;
        top: 4px;
    }
    

    .Mobile-menu {
        background-size: contain;
    }

    .close-icon {
        top: 5px;
        right: 20px;
        font-size: 40px;
    }
}
.Service-container {
    background: linear-gradient(rgb(5, 0, 8), rgb(21, 2, 31));
    width: auto;
    height: auto;
}

.Title-service {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Urbanist-Bold';
    font-size: 3vw;
    letter-spacing: 0.3rem;
    padding-top: 3vw;
    padding-bottom: 3vw;
}

.container-card {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-bottom: 4vw;
}


.card {
    width: 25vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2vw;
    margin-left: 2vw;
}

.card-image {
    width: 100%;
    height: 17vw;
}

.card-image img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
}

.card-text {
    position: relative;
    width: 100%;
    height: 25vw;
    background-color: rgb(255, 255, 255);
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title-card {
    font-family: 'Urbanist-Bold';
    font-size: 2.2vw;
    display: flex;
    padding-top: 2vw;
}

.into-card {
    font-family: 'Urbanist-Regular';
    font-size: 1.2vw;
}


ul {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0vw;
    margin-inline-start: 3vw;
    margin-inline-end: 3vw;
    padding-inline-start: 2vw;
}


.imagen-fondo-2 {
    display: none;
}





@media (max-width:768px) {
    .Service-container {
        background: linear-gradient(rgba(0, 0, 0, 1), rgb(22, 2, 32));
        width: 100vw;
        height: auto;
    }

    .Title-service {
        height: auto;
        padding-top: 17vw;
        padding-bottom: 5vw;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
        font-family: 'Urbanist-Bold';
        font-size: 12vw;
        letter-spacing: 0.3rem;
    }

    .container-card {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 0;
    }

    .card {
        position: relative;
        top: 0;
        width: auto;
        min-width: 0;
        max-width: 320px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
        margin-left: 0;
        padding: 10vw 5vw;
    }

    .card-image {
        width: 100%;
        height: auto;
        z-index: 3;
    }

    .card-image img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 35px;
        border-top-right-radius: 35px;
    }

    .card-text {
        position: relative;
        top: -8px;
        width: 100%;
        height: auto;
        background-color: white;
        border-bottom-left-radius: 35px;
        border-bottom-right-radius: 35px;
    }

    .title-card {
        font-family: 'Urbanist-Bold';
        font-size: 1.5rem;
        margin-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 8vw;
    }

    .into-card {
        font-family: 'Urbanist-Regular';
        font-size: 16px;
    }

    ul {
        display: block;
        margin-block-start: 4vw;
        margin-block-end: 3vw;
        margin-inline-start: 13vw;
        margin-inline-end: 13vw;
        padding-inline-start: 2vw;
    }

    .imagen-fondo-2 {
        background-image: url('/public/fotos/fotos-movil/imagen-medio.jpg');
        width: 100vw;
        display: flex;
        justify-content: center;
        overflow-x: hidden;
        height: 100vw;
        background-position: center;
        background-size: cover;
        opacity: 37%;
        margin-top: 7vw;
        padding-bottom: 2vw;
    }
}